export interface LocationZone {
  zone: string;
  position?: string;
  shiftLinks: ShiftLink[];
  shifts: Shifts[];
}

export interface ShiftContractor {
  id: number;
  name: string;
}

export interface ShiftClocker {
  id: number;
  time: string;
  original_time: string;
  image: string;
}
export interface ShiftLink {
  id: number;
  staff_id?: number;
  contractor_staff_id?: number;
  contractor?: ShiftContractor;
  role: string;
  start_id: number;
  end_id: number;
  user?: {
    id: number;
    full_name: string;
    image: string;
  };
  clock_in?: ShiftClocker;
  clock_out?: ShiftClocker;
  supervisor?: boolean;
  absent?: boolean;
  do_not_contact?: boolean;
  overtime_shift?: boolean;
  notes?: string;
  staff_notes?: string;
  reference?: string;
  location?: string;
  position?: string;
  deployment_zone?: string;
  status?: string;
  controller?: string;
  parentShift?: string;
}
export interface Shifts {
  id: number;
  date: string;
  start_time: string;
  end_time: string;
  job_id?: number;
  shift_links?: ShiftLink[];
  contractor_shift_links?: ShiftLink[];
  contractor?: ShiftContractor;
  sia: number;
  stewards: number;
  supervisors: number;
  supervisor?: number;
  sia_total?: number;
  stewards_total?: number;
  supervisors_total?: number;
  unassigned?: string[];
  reference?: string;
  position?: string;
  controller?: string;
  nameOfShift?: string;
  links?: ShiftLink[];
}

export enum StatusController {
  ShiftLink = 'ShiftLink',
  ContractorShiftLink = 'ContractorShiftLink',
  Regular = 'Regular',
  ShiftLinkPending = 'ShiftLinkPending',
  ContractorShiftLinkPending = 'ContractorShiftLinkPending',
  ShiftLinkLost = 'ShiftLinkLost',
}

export enum StatusString {
  Reset = 'reset',
  Received = 'received',
  Deployed = 'deployed',
}
