/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject, signal } from '@angular/core';
import { DeploymentFilterModel } from 'components/deployment/filter/src/lib/deployment-filter.type';
import { LocationZone } from 'components/deployment/zone/src/lib/deployment-zone.type';
import { WebsocketQuery, WebsocketService, WhereInput } from 'websocket';
import {
  CONTRACTOR_QUERY,
  CONTRACTOR_SHIFT_PENDING_QUERY,
  SHIFT_PENDING_QUERY,
  SHIFT_QUERY,
} from './shifts.query';
import { ShiftFilter } from './shifts.type';

@Injectable({
  providedIn: 'root',
})
export class ShiftsState {
  public readonly deploymentZones = signal<LocationZone[]>([]);
  public readonly filter = signal<DeploymentFilterModel>({});

  public readonly websocketService = inject(WebsocketService);

  public readonly shiftModel = new WebsocketQuery(
    SHIFT_QUERY.model,
    SHIFT_QUERY.query.fields,
    { with: SHIFT_QUERY.query.with },
  );

  public readonly shiftPendingModel = new WebsocketQuery(
    SHIFT_PENDING_QUERY.model,
    SHIFT_PENDING_QUERY.query.fields,
    { with: SHIFT_PENDING_QUERY.query.with },
  );

  public readonly contractorShiftPendingModel = new WebsocketQuery(
    CONTRACTOR_SHIFT_PENDING_QUERY.model,
    CONTRACTOR_SHIFT_PENDING_QUERY.query.fields,
    { with: CONTRACTOR_SHIFT_PENDING_QUERY.query.with },
  );

  public readonly contractorModel = new WebsocketQuery(
    CONTRACTOR_QUERY.model,
    CONTRACTOR_QUERY.query.fields,
  );

  public shiftQueries(filter: ShiftFilter) {
    const shift = this.shiftModel.clone();
    const shiftPending = this.shiftPendingModel.clone();
    const contractorShiftPending = this.contractorShiftPendingModel.clone();

    const where = {
      job_id: filter.job_id,
      date: filter.date,
    };

    shift.setCondition('where', where);
    shiftPending.setCondition('where', where);
    contractorShiftPending.setCondition('where', where);

    if (filter.start_time) {
      shift.updateCondition('where', (condition) => {
        return {
          ...condition,
          start_time: filter.start_time,
        } as WhereInput;
      });
    }

    if (filter.end_time) {
      shift.updateCondition('where', (condition) => {
        return {
          ...condition,
          end_time: filter.end_time,
        } as WhereInput;
      });
    }

    if (
      filter.status != undefined ||
      filter.company ||
      filter.deployment_zone ||
      filter.position
    ) {
      const shiftLinkWhere: WhereInput = {};
      const shiftLinkPendingWhere: WhereInput = {};
      const contractorShiftLinkWhere: WhereInput = {};
      const contractorShiftLinkPendingWhere: WhereInput = {};

      if (filter.status != undefined) {
        shiftLinkWhere['status'] = filter.status;
        shiftLinkPendingWhere['status'] = filter.status;
        contractorShiftLinkWhere['status'] = filter.status;
        contractorShiftLinkPendingWhere['status'] = filter.status;
      }

      if (filter.deployment_zone) {
        shiftLinkWhere['location'] = filter.deployment_zone;
        contractorShiftLinkWhere['deployment_zone'] = filter.deployment_zone;
        contractorShiftLinkPendingWhere['deployment_zone'] =
          filter.deployment_zone;
      }

      if (filter.position) {
        shiftLinkWhere['reference'] = filter.position;
        contractorShiftLinkWhere['position'] = filter.position;
        contractorShiftLinkPendingWhere['position'] = filter.position;
      }

      shift.setCondition('with', [
        {
          relation: 'shift_links',
          queries: {
            where: shiftLinkWhere,
          },
        },
        'shift_links.clock_in',
        'shift_links.clock_out',
        'shift_links.user:id,full_name,image',
        {
          relation: 'contractor_shift_links',
          queries: {
            where: contractorShiftLinkWhere,
            whereHas: filter.company
              ? {
                  contractor: {
                    'contractor_details.id': filter.company.filter(
                      (c) => c !== '0',
                    ),
                  },
                }
              : {},
          },
        },
        'contractor_shift_links.clock_in',
        'contractor_shift_links.clock_out',
        'contractor_shift_links.user:id,first_name,last_name,image',
        'contractor_shift_links.contractor',
      ]);

      shiftPending.setCondition('with', [
        {
          relation: 'shift_links',
          queries: {
            where: shiftLinkPendingWhere,
          },
        },
        'shift_links.clock_in',
        'shift_links.clock_out',
        'shift_links.user:id,full_name,image',
      ]);

      contractorShiftPending.setCondition('with', [
        {
          relation: 'shift_links',
          queries: {
            where: contractorShiftLinkPendingWhere,
            whereHas: filter.company
              ? {
                  contractor: {
                    'contractor_details.id': filter.company.filter(
                      (c) => c !== '0',
                    ),
                  },
                }
              : {},
          },
        },
        'shift_links.clock_in',
        'shift_links.clock_out',
        'shift_links.user:id,first_name,last_name,image',
        'shift_links.contractor',
        'contractor:id,name',
      ]);
    }

    if (filter.company) {
      const includeStambridge = filter.company.includes('0');

      // If only contractor
      if (!includeStambridge) {
        shift.updateCondition('with', (condition) => {
          return condition?.slice(4);
        });

        return {
          ...shift.getQuery(),
          ...contractorShiftPending.getQuery(),
        };
      }

      // If only Stambridge
      if (filter.company.length === 1) {
        shift.updateCondition('with', (condition) => {
          return condition?.slice(0, 4);
        });

        // Skip shiftPending
        if (filter.deployment_zone || filter.position) {
          return {
            ...shift.getQuery(),
          };
        }

        return {
          ...shift.getQuery(),
          ...shiftPending.getQuery(),
        };
      }
    }

    // Skip shiftPending
    if (filter.deployment_zone || filter.position) {
      return {
        ...shift.getQuery(),
        ...contractorShiftPending.getQuery(),
      };
    }

    return {
      ...shift.getQuery(),
      ...shiftPending.getQuery(),
      ...contractorShiftPending.getQuery(),
    };
  }

  public contractorQueries(filter: { job_id: number }) {
    const contractor = this.contractorModel.clone();

    contractor.setCondition('where', {
      hasShift: {
        value: filter.job_id,
        operator: 'scope',
      },
    });

    contractor.setCondition('orderBy', {
      field: 'name',
      type: 'asc',
    });

    return contractor.getQuery();
  }
}
