export const SHIFT_QUERY = {
  model: 'Shift',
  query: {
    fields: ['id', 'date', 'start_time', 'end_time', 'job_id', 'reference'],
    with: [
      'shift_links',
      'shift_links.clock_in',
      'shift_links.clock_out',
      'shift_links.user:id,full_name,image',
      'contractor_shift_links',
      'contractor_shift_links.clock_in',
      'contractor_shift_links.clock_out',
      'contractor_shift_links.user:id,first_name,last_name,image',
      'contractor_shift_links.contractor',
    ],
  },
};

export const SHIFT_PENDING_QUERY = {
  model: 'ShiftPending',
  query: {
    fields: ['id', 'date', 'job_id', 'position'],
    with: [
      'shift_links',
      'shift_links.clock_in',
      'shift_links.clock_out',
      'shift_links.user:id,full_name,image',
    ],
  },
};

export const CONTRACTOR_SHIFT_PENDING_QUERY = {
  model: 'ContractorShiftPending',
  query: {
    fields: ['id', 'date', 'job_id', 'position', 'contractor_id'],
    with: [
      'shift_links',
      'shift_links.clock_in',
      'shift_links.clock_out',
      'shift_links.user:id,first_name,last_name,image',
      'shift_links.contractor',
      'contractor:id,name',
    ],
  },
};

export const CONTRACTOR_QUERY = {
  model: 'ContractorDetail',
  query: {
    fields: ['id', 'name'],
  },
};
