import { Injectable, computed, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'auth';
import {
  LocationZone,
  ShiftContractor,
  ShiftLink,
  Shifts,
  StatusController,
} from 'components/deployment/zone/src/lib/deployment-zone.type';
import { LoadingService } from 'loading';
import { NotificationService } from 'notification';
import { StorageService } from 'storage';
import { WebsocketService } from 'websocket';
import { ShiftsState } from './shifts.state';
import { ShiftFilter, StaffQr } from './shifts.type';

@Injectable({
  providedIn: 'root',
})
export class ShiftsResolver {
  readonly websocketService = inject(WebsocketService);
  readonly notificationService = inject(NotificationService);
  readonly loadingService = inject(LoadingService);
  readonly dialog = inject(MatDialog);
  readonly authService = inject(AuthService);
  readonly state = inject(ShiftsState);
  readonly router = inject(Router);
  readonly storage = inject(StorageService);

  logout() {
    this.authService.logout();
    this.authService.storage.remove('job');
    this.router.navigate(['/auth']);
  }

  public readonly job = computed(() => {
    return this.authService.storage.get('job');
  });

  getShifts(filter: ShiftFilter) {
    this.loadingService.show();

    this.websocketService
      .query<{
        ContractorShiftPending: Shifts[];
        Shift: Shifts[];
        ShiftPending: Shifts[];
      }>(this.state.shiftQueries(filter))
      .subscribe(({ data }) => {
        this.loadingService.hide();

        this.state.deploymentZones.set(
          this.groupShiftByLocationStaff([
            {
              items: data.ContractorShiftPending || [],
              controller: StatusController.ContractorShiftLinkPending,
            },
            { items: data.Shift || [], controller: StatusController.Regular },
            {
              items: data.ShiftPending || [],
              controller: StatusController.ShiftLinkPending,
            },
          ]),
        );
      });
  }

  getContractorInJob(job_id: number) {
    return this.websocketService.query<{
      ContractorDetail: ShiftContractor[];
    }>(this.state.contractorQueries({ job_id }));
  }

  checkPassword(password: string) {
    this.loadingService.show();

    return this.authService
      .login({ email: '', password: password })
      .then((res) => {
        this.loadingService.hide();
        const job = this.storage.get('job');
        if (res.job_id === job.id) {
          return true;
        }

        this.notificationService.error('Password is incorrect');
        return false;
      })
      .catch(() => {
        this.loadingService.hide();
        this.notificationService.error('Password is incorrect');
        return false;
      });
  }

  getStaff(code: string) {
    this.loadingService.show();

    return this.websocketService.query<{ StaffQr: StaffQr }>({
      StaffQr: {
        fields: ['id', 'code', 'staff_id', 'staff_type'],
        first: true,
        where: {
          code: code.split(':').pop() as string,
        },
      },
    });
  }

  public hasJob() {
    const job = this.authService.storage.get('job');

    if (!job) {
      this.logout();
      return false;
    }

    return true;
  }

  groupShiftByLocationStaff(
    shiftsData: {
      items: Shifts[];
      controller: string;
    }[],
  ): LocationZone[] {
    const result = [] as LocationZone[];
    const staffLinks: ShiftLink[] = [];
    const dataShift = [] as Shifts[];
    shiftsData.forEach((shift) => {
      shift.items.forEach((item, index) => {
        //get data of shift
        const nameOfShift = `${shift.controller}-${index}`;
        const data = {
          ...item,
          nameOfShift,
          controller: shift.controller,
        };
        //clear all shift links
        data.shift_links = [];
        data.contractor_shift_links = [];

        //add new links array
        data.links = [];
        dataShift.push(data);

        //push all staff link to staffLinks
        if (item.shift_links && item.shift_links.length > 0) {
          item.shift_links.forEach((link) => {
            staffLinks.push({
              ...link,
              controller:
                shift.controller === StatusController.Regular
                  ? StatusController.ShiftLink
                  : shift.controller,
              parentShift: nameOfShift,
            });
          });
        }
        if (
          item.contractor_shift_links &&
          item.contractor_shift_links.length > 0
        ) {
          item.contractor_shift_links.forEach((link) => {
            staffLinks.push({
              ...link,
              controller:
                shift.controller === StatusController.Regular
                  ? StatusController.ContractorShiftLink
                  : shift.controller,
              parentShift: nameOfShift,
            });
          });
        }
      });
    });

    staffLinks.forEach((item) => {
      const zone = item.location || item.deployment_zone || 'Unassigned';
      const findIndex = result.findIndex((group) => group.zone === zone);

      if (findIndex !== -1) {
        result[findIndex].shiftLinks.push(item);
      } else {
        result.push({
          zone: zone,
          shiftLinks: [item],
          shifts: [],
        });
      }
    });

    result.forEach((item) => {
      item.shiftLinks.forEach((link) => {
        const parentShift = link.parentShift;
        const findIndex = item.shifts.findIndex(
          (group) => group.nameOfShift === parentShift,
        );
        if (findIndex !== -1) {
          item.shifts[findIndex].links?.push(link);
        } else {
          const findShift = dataShift.find(
            (shift) => shift.nameOfShift === parentShift,
          ) as Shifts;

          const newItem = {
            ...findShift,
            links: [link],
          };

          item.shifts.push(newItem);
        }
      });
    });

    return result;
  }
}
